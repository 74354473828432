import './src/styles/global.css'

export const onInitialClientRender = () => {
    function initApollo() {
      const n = Math.random().toString(36).substring(7);
      const scriptEl = document.createElement("script");
      scriptEl.src = "https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=" + n;
      scriptEl.async = true;
      scriptEl.defer = true;
      scriptEl.onload = function() {
        window.trackingFunctions.onLoad({ appId: "6633217272fc6a0302197cd2" });
      };
      document.head.appendChild(scriptEl);
    }
  
    initApollo();
  };